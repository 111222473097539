export * from "./batch"
export * from "./pricing"
export * from "./dropzone"

export const DEFAULT_LOGO_URL = "/img/brand/logo_right_dark.png"
export const BLANK_IMAGE_URL = "/img/blank.png"
export const queryCacheTime = 1000 * 60 * 60 * 24 // 24 hours
export const accessTokenExpiredMessage = "refresh_access_token"
export const broadcastChannelName = "memCache-updates"
export const LOGIN_URL = "/auth/oauth2/token"
export const FETCH_DATA_LIMIT = 100
export const UPDATE_DATA_THRESHOLD = 999
export const LOCALSTORAGE_GENERAL_KEY = "__smartpart__"

