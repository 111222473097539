import type { ComputedRef, Ref } from "vue"
import { cloneDeepWith, isPlainObject } from "lodash-es"
import { unref } from "vue"

export type Modify<T, K> = Omit<T, keyof K> & K

export type Require<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: T[P] }

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export type Nullify<T, K extends keyof T> = Omit<T, K> & {
  [P in K]+?: null | undefined
}

export type ApiError = {
  code: number
  status: string
  message?: string
  errors?: any
}

export type MaybeReadonly<T> = Readonly<T> | T

export type QueryKey = string | readonly unknown[]
export type EnsuredQueryKey<T extends QueryKey = QueryKey> = T extends string ? [T] : Exclude<T, string>

export type ObjectWithId = { id: number }
export type IdBox<T extends ObjectWithId = ObjectWithId> = number | PartialWithId<T> | ObjectWithId

// @ts-expect-error TS won't think this'll work, but it will.
export const getId = (id?: IdBox): number => id?.id || id

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never
type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? never : K
}[keyof T]
type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K : never
}[keyof T]
type RequiredIdfy<T> = {
  [P in RequiredKeys<T>]-?: T[P] extends ObjectWithId
    ? IdBox<T[P]>
    : T[P] extends ObjectWithId[]
    ? IdBox<ArrayElement<T[P]>>[]
    : T[P]
}
type OptionalIdfy<T> = {
  [P in OptionalKeys<T>]?: T[P] extends ObjectWithId | undefined
    ? IdBox<T[P]>
    : T[P] extends ObjectWithId[] | undefined
    ? IdBox<ArrayElement<T[P]>>[]
    : T[P]
}
export type Idfy<T> = RequiredIdfy<T> & OptionalIdfy<T>
export type WrappedInComputed<T> = {
  [P in keyof T]: ComputedRef<T[P]>
}
export type MaybeRef<T> = Ref<T> | T
type MaybeArray<T> = T[] | T

export type PartialWithId<T> = Modify<Partial<T>, { id: number }>

export function findOrMakeBasic<T extends ObjectWithId>(list: Array<T>, id: number): T {
  return list.find(item => item.id === id) || ({ id } as unknown as T)
}

export const unwrapRefs = (key: QueryKey) => {
  key = ensureQueryKeyArray(key)
  return key.map(unref)
}

export const hashKey = (key: QueryKey): string => {
  if (typeof key === "string" && key.startsWith("[")) return key

  const arrayKey = ensureQueryKeyArray(key)
  const unreffedKey = arrayKey.map(key => cloneDeepWith(key, unref))

  return stableValueHash(unreffedKey)
}

export const isInvalid = (v: any) => [undefined, null, NaN].includes(v)

export const url = (...parts: MaybeArray<unknown[]>) => {
  const url = unwrapRefs(parts.flat()).join("/")
  return url.startsWith("/v1") ? url : `/v1/${url}`
}

export function ensureQueryKeyArray<T extends QueryKey>(value: T): EnsuredQueryKey<T> {
  return (Array.isArray(value) ? value : ([value] as unknown)) as EnsuredQueryKey<T>
}

/**
 * Default query keys hash function.
 */
export function hashQueryKey(queryKey: QueryKey): string {
  const asArray = ensureQueryKeyArray(queryKey)
  return stableValueHash(asArray)
}

/**
 * Hashes the value into a stable hash.
 */
export function stableValueHash(value: any): string {
  return JSON.stringify(value, (_, val) =>
    isPlainObject(val)
      ? Object.keys(val)
          .sort()
          .reduce((result, key) => {
            result[key] = val[key]
            return result
          }, {} as any)
      : val
  )
}

// TODO: Saw this in an article somewhere,
// not sure yet if it can be useful for us...
// I'm not using this function anywhere yet,
// so if you think it's unnecessary,
// then feel free to delete it.
export const keysFactory = (baseKey: string) => {
  const keys = {
    all: [baseKey] as const,
    lists: () => [...keys.all, "list"] as const,
    list: (filters: string) => [...keys.lists(), { filters }] as const,
    details: () => [...keys.all, "detail"] as const,
    detail: (id: number) => [...keys.details(), id] as const,
  }

  return keys
}
