import type { DebounceSettings } from "lodash-es"
import { noop, throttle, debounce } from "lodash-es"
import { get as getIdb, set as setIdb } from "idb-keyval"
import { hash as hashData } from "@/hasher"
import {
  broadcastChannelName,
  queryCacheTime,
  accessTokenExpiredMessage,
} from "@/constants"
import sleep from "./libraries/helpers/sleep"
import { queueCallsToFunc } from "@/libraries/helpers/functionQueuer"
import type { ObjectWithId, QueryKey } from "./interfaces"
import { hashQueryKey, httpStatusCodes } from "@/interfaces"

export { noop, hashData as hash }

const sw = self as unknown as ServiceWorkerGlobalScope

type AnyFn = (...args: any) => any
type DebouncedFunc<T extends AnyFn> = ReturnType<typeof debounce<T>> & {
  pending(): boolean
}

export type Primitive = number | string | boolean | bigint | symbol | undefined | null

export type PrimitiveExtended = Primitive | Date | Function

export interface MostlyPlainObject {
  [key: string]:
    | PrimitiveExtended
    | PrimitiveExtended[]
    | MostlyPlainObject
    | MostlyPlainObject[]
}

type MostlyApiObject = ObjectWithId & MostlyPlainObject

export type OneOrMoreApiObjects = MostlyApiObject | MostlyApiObject[]
export type Jsonable =
  | MostlyPlainObject
  | MostlyApiObject
  | Exclude<PrimitiveExtended, Function>
export type Jsonables = Jsonable | Jsonable[]

export interface MemCacheValue<T extends Jsonables = Jsonables> {
  data: T
  updatedAt: number
  hash: string
}

export type MemCache = Record<string, MemCacheValue> // & { id: string }

// This is my in-memory cache,
// which I'll be using most of the time.
// Also, if you want to change these 3 lines,
// then also change the same lines in src/interfaces/cache.ts
const storeName = "SPKVStore"
let memCache: MemCache = {}
let memCacheHash = ""

export type GetCommand = {
  cmd: "get"
}

export type PatchCommand = {
  cmd: "patch"
  key: string
  data: Jsonables
  hash: string
  updatedAt: number
}

export type PutCommand = {
  cmd: "put"
  memCache: MemCache
}

export type DeleteCommand = {
  cmd: "delete"
  key: string
}

export type ClearCommand = {
  cmd: "clear"
}

export type RetryRequestsCommand = {
  cmd: "retry_requests_after_token_refresh"
  access_token: string
}

export type KeepTryingCommand = {
  cmd: "keep_trying"
  key: string
}

export type StopTryingCommand = {
  cmd: "stop_trying"
  key: string
}

export type Command =
  | GetCommand
  | PatchCommand
  | PutCommand
  | DeleteCommand
  | ClearCommand
  | KeepTryingCommand
  | StopTryingCommand
  | RetryRequestsCommand

export type MemCacheMessage = {
  memCache: MemCache
  memCacheHash: string
}

export type MemCachePatchMessage = MemCacheValue & { key: string }

const broadcastChannel = new BroadcastChannel(broadcastChannelName)

const broadcastMemCache = throttle(() => {
  const data = { memCache, memCacheHash }
  broadcastChannel.postMessage(data)
  return data
}, 1000)

let messagePort: MessagePort

export function setupMessagePort(port: MessagePort) {
  messagePort && (messagePort.onmessage = null)
  messagePort = port
  messagePort.onmessage = handleMessage
}

type MessageClientOptions = { message: any } & ({ clientId: string } | { client: Client })

async function sendMessageToClient(options: MessageClientOptions) {
  const client =
    "client" in options
      ? options.client
      : options.clientId !== ""
      ? await sw.clients.get(options.clientId)
      : messagePort

  if (!client)
    throw new Error("No client found. Did you forget to set up a message port?")

  return client.postMessage(options.message)
}

function groupedDebounce<T extends (...args: any[]) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings & {
    key?: (...args: Parameters<T>) => string | number
  }
) {
  const dict: Record<string | number, DebouncedFunc<T>> = {}
  const callCount: Record<string | number, number> = {}

  return (...args: Parameters<T>) => {
    const key = options?.key?.(...args) || JSON.stringify(args)
    dict[key] ??= debounce(func, wait, options) as DebouncedFunc<T>
    if (callCount[key] !== 20) callCount[key] = (callCount[key] || 0) + 1
    if (callCount[key] < 20) dict[key].flush()
    return dict[key](...args)
  }
}

let broadcastedListPatches = 0

const broadcastListPatch = groupedDebounce(
  (key: string) => {
    if (broadcastedListPatches++ > 25) {
      broadcastedListPatches = 0
      return broadcastMemCache()
    }

    const data = { ...memCache[key], key }
    broadcastChannel.postMessage(data)
    return data
  },
  typeof window === "undefined" ? 250 : 2000,
  { leading: typeof window !== "undefined", key: key => key }
)

const storeNewCache = async (newMemCache: MemCache, broadcast = true) => {
  const newMemCacheHash = await hashData(newMemCache)
  if (newMemCacheHash === memCacheHash) return

  try {
    memCacheHash = newMemCacheHash
    memCache = newMemCache
    broadcast && broadcastMemCache()
    persistMemCache()
  } catch (error) {
    console.error(error)
  }
}

const persistMemCache = throttle(async () => {
  await setIdb(storeName, memCache)
}, 1000)

export const fetchMemCache = throttle(async function innerFetchMemCache(): Promise<MemCacheMessage> {
  const now = Date.now()
  try {
    memCache = (await getIdb(storeName)) || {}
  } catch {
    await sleep(1050)
    return innerFetchMemCache()
  }

  // This is to clean up cache entries that are way too old...
  memCache = Object.keys(memCache).reduce((newMemCache, key) => {
    if (now - memCache[key].updatedAt < queryCacheTime) newMemCache[key] = memCache[key]

    return newMemCache
  }, {} as MemCache)

  memCacheHash = await hashData(memCache)

  return { memCache, memCacheHash }
}, 1000)

function correctKey(key: QueryKey): string {
  if (typeof key === "string" && key.startsWith("[")) return key

  return hashQueryKey(key)
}

export async function putCacheEntry(args: {
  key: string
  data: Jsonables
  hash?: string
  updatedAt?: number
  broadcast?: "memCache" | "entry"
}) {
  const {
    data,
    updatedAt = Date.now(),
    hash = await hashData(data),
    broadcast = "entry",
  } = args

  const key = correctKey(args.key)

  // this is not needed because memCache always updated
  // // prettier-ignore
  // if (memCache[key]?.hash === hash)
  //   return // console.log("no list update")

  memCache[key] = { data, updatedAt, hash }
  storeNewCache(memCache, broadcast === "memCache")
  if (broadcast === "entry") broadcastListPatch(key)
}

function getEntry(args: { key: QueryKey; id?: undefined }): {
  list: MostlyApiObject[] | undefined
  listItem: undefined
}

function getEntry(args: { key: QueryKey; id: number }): {
  list: MostlyApiObject[] | undefined
  listItem: MostlyApiObject | undefined
}

function getEntry(args: { key: QueryKey; id?: number }) {
  const key = correctKey(args.key)
  let keys = [key],
    result = { list: undefined, listItem: undefined }
  const { id } = args

  if (id && /^\["\w+"]$/.test(key)) {
    keys = Object.keys(memCache).filter(mcKey => mcKey.endsWith(key.slice(1)))
  }

  for (const key of keys) {
    const entry = memCache[key]
    if (!entry) continue

    const data = entry.data as OneOrMoreApiObjects

    const list = Array.isArray(data) ? data : undefined
    const listItem = list ? id && list.find(item => item.id === id) : data

    if (list && id && !listItem) {
      if (key === args.key) result = { list, listItem }
      continue
    }

    return { list, listItem: listItem as MostlyApiObject }
  }

  return result
}

const rollbacks: Record<string, boolean | (() => void)> = {}

function changeEntry(args: {
  listKey: string
  id?: number
  data?: MostlyPlainObject
  method: "POST" | "PATCH" | "DELETE"
  broadcast?: "memCache" | "entry"
}) {
  const { data, method, broadcast = "entry" } = args

  const key = correctKey(args.listKey)
  const { list } = getEntry({ key })
  if (!list) return noop

  const index = !args.id ? undefined : list.findIndex(item => item.id === args.id)

  const originalItem = list[index]

  const id = args.id || originalItem?.id || Math.round(Math.random() * 1e16)

  const newItem = { ...originalItem, ...data, id }

  const methodMap: Record<typeof method, () => void> = {
    POST: () => list.splice(0, 0, newItem),
    PATCH: () => list.splice(index, 1, newItem),
    DELETE: () => list.splice(index, 1),
  }

  methodMap[method]()

  putCacheEntry({
    key,
    data: list,
    broadcast,
  })

  return queueCallsToFunc(
    ({ replaceWith = originalItem } = {}) => {
      const { list } = getEntry({ key })
      const i =
        method === "DELETE" || list[index] === newItem
          ? index
          : list.findIndex(item => item.id === id)
      list.splice(i, +(method !== "DELETE"), ...[replaceWith || []].flat())

      putCacheEntry({
        key,
        data: list,
        broadcast,
      })
    },
    { key: () => `${key}_rollback` }
  )
}

const queueEntryChange = queueCallsToFunc(changeEntry, {
  key: ({ listKey }) => listKey,
})

export function removeItem(key: string) {
  if (!memCache[key]) return

  delete memCache[key]
  return storeNewCache(memCache)
}

export async function clearCache() {
  await caches.delete(cacheName)
  storeNewCache({})
}

const cacheName = "cache-__DATE__"
export type EMEvent<T extends Command = Command> =
  | MessageEvent<T>
  | (ExtendableMessageEvent & { data: T })

type ValueOf<T> = T[keyof T]

type NonEmptyArray<T> = [T, ...T[]]

type MustInclude<T, U extends T[]> = [T] extends [ValueOf<U>] ? U : never

function stringUnionToArray<T>() {
  return <U extends NonEmptyArray<T>>(...elements: MustInclude<T, U>) => elements
}

const allCommands = stringUnionToArray<Command["cmd"]>()(
  "get",
  "put",
  "patch",
  "delete",
  "clear",
  "keep_trying",
  "stop_trying",
  "retry_requests_after_token_refresh"
)

function isCommand<T extends Command = Command>(
  event: ExtendableMessageEvent | MessageEvent | EMEvent<T>
): event is EMEvent<T> {
  if (typeof event.data !== "object") return false
  if (!("cmd" in event.data)) return false

  return allCommands.includes(event.data.cmd)
}

const requestsToRetry: Record<string, Request[]> = {}

const postMessageFactory = <T extends globalThis.Worker, U extends globalThis.Window>(
  messenger: T | U,
  targetOrigin?: string
) => ({
  postMessage(message: any) {
    switch (messenger.postMessage.length) {
      case 2:
        return (messenger as T).postMessage(message, [])
      case 3:
        return (messenger as U).postMessage(message, targetOrigin)
    }
  },
})

export async function handleMessage<C extends Command>(
  this: ServiceWorkerGlobalScope | MessagePort,
  event: EMEvent<C>
) {
  if (!isCommand(event)) return
  const data = event.data as Command

  const source =
    event.ports[0] ||
    (event.source != null
      ? event.source
      : this instanceof MessagePort
      ? this
      : messagePort instanceof MessagePort
      ? messagePort
      : postMessageFactory(self, event.origin))

  switch (data.cmd) {
    case "get": {
      const response = await fetchMemCache()
      return source.postMessage(response)
    }

    case "patch":
      putCacheEntry(data)
      break

    case "put":
      storeNewCache(data.memCache)
      break

    case "delete":
      removeItem(data.key)
      break

    case "clear": {
      const cleared = await caches.delete(cacheName)
      if (!cleared) return source.postMessage({ cleared })
      storeNewCache({})
      break
    }

    case "keep_trying":
      rollbacks[data.key] ??= true
      break

    case "stop_trying": {
      const rb = rollbacks[data.key]
      if (typeof rb === "function") rb()
      delete rollbacks[data.key]
      break
    }

    case "retry_requests_after_token_refresh": {
      const client = event.source as Client
      retryRequests(client, data.access_token)
      break
    }

    default:
      throw new Error(
        `Don't recognize command ${(data as any).cmd}, the options are ${allCommands.join(
          ", "
        )}.`
      )
  }

  return source.postMessage(true)
}

const retryRequests = async (client: Client, access_token: string) => {
  const requests = requestsToRetry[client.id] || []

  const cache = await caches.open(cacheName)

  const filterAnswers = await Promise.all(
    requests.map(async oldRequest => {
      const request = new Request(oldRequest, {
        headers: {
          ...oldRequest.headers,
          Authorization: `Bearer ${access_token}`,
        },
      })

      const url = new URL(request.url)
      const key = url.pathname
        .replace(/^\/v1(_1)?\//, "")
        .split("/")
        // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
        .map(p => (isNaN(+p) ? p : +p))
      const hashedKey = JSON.stringify(key)

      const networkResponse = await originalFetch(request).catch(() => {})
      if (!networkResponse) return true
      if (!networkResponse.ok) return true
      cache.put(request, networkResponse.clone())

      networkResponse
        .clone()
        .json()
        .then(data => putCacheEntry({ key: hashedKey, data }))
        .catch()

      return false
    })
  )

  requestsToRetry[client.id] = requests.filter((_, i) => filterAnswers[i])
  if (!requestsToRetry[client.id].length) delete requestsToRetry[client.id]
}

export const { fetch: originalFetch } = self

const cacheImages = (request: Request) => {
  if (request.method !== "GET") return

  const isLogo = request.url.includes("/logo?key=")
  const req = isLogo ? encodeURIComponent(request.url) : request

  // Open the cache
  return caches.open(cacheName).then(cache =>
    // Respond with the image from the cache or from the network
    cache.match(req).then(
      cachedResponse =>
        cachedResponse ||
        originalFetch(req).then(fetchedResponse => {
          // Add the network response to the cache for future visits.
          // Note: we need to make a copy of the response to save it in
          // the cache and use the original as the request response.
          if (fetchedResponse.ok) cache.put(req, fetchedResponse.clone())

          // Return the network response
          return fetchedResponse
        })
    )
  )
}

export const handleRequest = async ({
  request,

  // If the clientId default isn't set to "",
  // then retryRequests() would fail in window context
  clientId = "",
}: {
  request: Request
  clientId?: string
}) => {
  const url = new URL(request.url)

  // Is this a request for an image or a model?
  if (
    url.pathname.includes("/images") ||
    url.pathname.includes("/models") ||
    url.pathname.includes("/logo?key=")
  )
    return cacheImages(request)

  if (!url.pathname.startsWith("/v1")) return
  if (url.pathname.startsWith("/v1/auth")) return

  const key = url.pathname
    .replace(/^\/v1(_1)?\//, "")
    .split("/")
    // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
    .map(p => (isNaN(+p) ? p : +p))
  const hashedKey = JSON.stringify(key)
  const method = request.method as "POST" | "PATCH" | "DELETE"

  if (key.length === 1 && key[0] === "files" && method === "POST") return

  if (["POST", "PATCH", "DELETE"].includes(method)) {
    if (typeof rollbacks[hashedKey] === "function") return
    if (![1, 2].includes(key.length)) return
    const [listKey, id] = key as [string, number]
    if (typeof id === "string") return

    const { list, listItem } = getEntry({ key: listKey, id })
    if (method === "POST" && !list) return
    if (method !== "POST" && !listItem) return

    const data = method === "DELETE" ? null : await request.clone().json()
    let rollback = noop
    if (typeof rollbacks[hashedKey] !== "function") {
      rollback = await queueEntryChange({
        listKey,
        id,
        data,
        method,
      })

      if (rollbacks[hashedKey] === true) rollbacks[hashedKey] = rollback
    }

    return new Promise<Response>((resolve, reject) =>
      originalFetch(request)
        .then(async resp => {
          resolve(resp.clone())

          if (!resp?.ok) return !rollbacks[hashedKey] && rollback()
          const newData = method !== "DELETE" ? await resp.clone().json() : null

          rollback({ replaceWith: newData })
          delete rollbacks[hashedKey]
        })
        .catch(reject)
    )
  }

  if (request.method !== "GET") return

  const updateCachedResponse = (response: Response) => {
    const maybeId = +key.at(-1) || undefined
    const listKey = maybeId ? JSON.stringify(key.slice(0, -1)) : hashedKey
    const { list, listItem } = getEntry({ key: listKey, id: maybeId })
    if (maybeId && !listItem) return response
    const json = JSON.stringify(listItem || list)

    return !json
      ? response
      : new Response(json, {
          headers: response?.headers || {
            "content-type": "application/json",
            "content-length": `${json.length}`,
          },
          status: response?.status || 200,
          statusText: response?.statusText || "OK",
        })
  }

  return caches.open(cacheName).then(cache =>
    cache.match(request).then(cachedResponse => {
      const newResponse = updateCachedResponse(cachedResponse)
      newResponse && cache.put(request, newResponse.clone())

      const fetchedResponse = originalFetch(request).then(async networkResponse => {
        const { ok, status, url } = networkResponse

        if (ok) {
          cache.put(request, networkResponse.clone())
          const data = await networkResponse.clone().json()
          putCacheEntry({ key: hashedKey, data })

          requestsToRetry[clientId] = requestsToRetry[clientId]?.filter(req => req.url !== request.url)
        } else if (status === httpStatusCodes.UNAUTHORIZED && !url.includes("/oauth2/token")) {
          const clientRequestsToRetry = requestsToRetry[clientId] || []

          if (!clientRequestsToRetry.length)
            sendMessageToClient({
              clientId,
              message: accessTokenExpiredMessage,
            })

          if (!clientRequestsToRetry.find(req => req.url === request.url))
            requestsToRetry[clientId] = clientRequestsToRetry.concat(request)
        }

        return networkResponse
      })

      return newResponse || fetchedResponse
    })
  )
}

if (typeof ServiceWorkerGlobalScope !== "undefined") {
  sw.addEventListener("activate", event => event.waitUntil(fetchMemCache()))
  sw.addEventListener("message", handleMessage)
}

if (typeof window !== "undefined") {
  window.fetch = async function (input, init) {
    const request = new Request(input, init)
    const response = await handleRequest({ request })
    return response || originalFetch.bind(this)(request)
  }
}
