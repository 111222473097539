import type { Jsonables, MemCache, MemCacheValue } from "./idb"
import { xxhash64 as hashString } from "hash-wasm"

export const hash = (obj: Jsonables | MemCache | MemCacheValue) => {
  if (obj == null) return ""
  if (typeof obj !== "object") return hashString(JSON.stringify(obj))

  if ("data" in obj) return hashString(JSON.stringify((obj as MemCacheValue).data))

  if (Array.isArray(obj)) return hashString(JSON.stringify(obj))

  if (Object.values(obj).every(v => typeof v === "object" && "hash" in v)) {
    const compactMC = Object.keys(obj as MemCache)
      .sort()
      .reduce((newMC, key) => {
        newMC[key] = obj[key].hash
        return newMC
      }, {})

    return hashString(JSON.stringify(compactMC))
  }

  return hashString(JSON.stringify(obj))
}
