// I'm making this function because requestIdleCallback
// is not yet supported in all modern browsers.
export const enqueueTask = (task: () => void) => {
  const channel = new MessageChannel()
  channel.port1.onmessage = () => task()
  channel.port2.postMessage(null)
}

const queue = []
const runningFuncs: Record<string | number, boolean> = {}

export function queueCallsToFunc<T extends (...args: any[]) => any>(
  func: T,
  options?: { key: (...args: Parameters<T>) => string | number }
) {
  return (...args: Parameters<T>) => {
    const key =
      options?.key(...args) ||
      JSON.stringify({
        name: func.name,
        args,
        body: func.toString(),
        source: new Error().stack?.split(/\n\s*at /)[2],
      })

    const upgradedFunc = (...args: Parameters<T>) => {
      runningFuncs[key] = true
      const result = func(...args)
      result instanceof Promise
        ? result.then(r => (delete runningFuncs[key], r)).catch()
        : delete runningFuncs[key]
      return result as ReturnType<T>
    }

    if (!runningFuncs[key]) return upgradedFunc(...args)

    queue.push(args)

    return new Promise<Awaited<ReturnType<T>>>(resolve => {
      const tryToResolve = () => {
        if (runningFuncs[key] || queue[0] !== args) return enqueueTask(tryToResolve)

        resolve(upgradedFunc(...queue.shift()))
      }

      enqueueTask(tryToResolve)
    })
  }
}
